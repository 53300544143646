import React from "react"
import Layout from "../components/Layout"
import Challenge from "../images/CHALLENGE-Pop-Up.png"
import Achievement from "../images/ACHIEVEMENT-Pop-Up.png"
import Learning from "../images/LEARNING-Pop-Up.png"
import Motivation from "../images/MOTIVATION-Pop-Up.png"
import Reward from "../images/Reward-Pop-Up.png"
import User from "../images/USER-ENGAGEMENT-Pop-Up.png"
import BoyBody from "../images/Boy-Body.png"
import BoyHead from "../images/Boy-Head.png"
import Cup from "../images/Cup.png"
import Medal from "../images/Medal.png"
import GamificationSh from "../images/Gamification-With-Shadow.png"
import Gamification from "../images/Gamification.png"
import KidDegree from "../images/Kid-Degree.png"
import KidDegreeHand from "../images/Kids-Degree-Hand.png"
import KidPlayFullBody from "../images/Kid-Play-Full-Body.png"
import KidPlayingHand from "../images/Kid-Playing-Hand.png"
import KidReadingBookBody from "../images/Kid-Reading-Book-Body.png"
import KidReadingBookHead from "../images/Kid-Reading-Book-Head.png"
import Kidsskipping1 from "../images/Kids-skipping-1.png"
import Kidsskipping2 from "../images/Kids-skipping-2.png"
import SuperGirl from "../images/Super-Girl-Full.png"

export default function Home() {
  return (
    <Layout>
      <section class="text-gray-600 pt-[5%] body-font">
        <div class="container relative mx-auto flex px-5 items-center justify-center flex-col">
          <img
            class="w-[6%] top-[-50%] left-[35%] object-cover object-center rounded absolute animate__animated animate__rotateInDownLeft animate__delay-1s"
            alt="hero"
            src={Reward}
          />
          <img
            class="w-[8%] top-[-53%] left-[64%] object-cover object-center rounded absolute animate__animated animate__rotateInDownLeft animate__delay-2s"
            alt="hero"
            src={Achievement}
          />
          <img
            class="w-[8%] top-[-33%] right-[9%] object-cover object-center rounded absolute animate__animated animate__rotateInDownLeft animate__delay-3s"
            alt="hero"
            src={Motivation}
          />
          <img
            class="w-[12%] top-[100%] right-[12%] object-cover object-center rounded absolute animate__animated animate__rotateInDownLeft animate__delay-4s"
            alt="hero"
            src={Challenge}
          />
          <img
            class="w-[9%] top-[100%] left-[30%] object-cover object-center rounded absolute animate__animated animate__rotateInDownLeft animate__delay-5s"
            alt="hero"
            src={Learning}
          />
          <img
            class="w-[7%] top-[-6%] left-[10%] object-cover object-center rounded absolute animate__animated animate__rotateInDownLeft"
            alt="hero"
            src={User}
          />
          <img
            class="w-[11%] top-[35%] left-[9%] object-cover object-center rounded absolute"
            alt="hero"
            src={KidPlayFullBody}
          />
          <img
            class="w-[2%] top-[64%] left-[13.2%] object-cover object-center rounded absolute animate-wiggle"
            alt="hero"
            src={KidPlayingHand}
          />
          <img
            class="w-[7%] top-[12%] left-[32.5%] object-cover object-center rounded absolute"
            alt="hero"
            src={BoyBody}
          />
          <img
            class="w-[6%] top-[-20%] left-[33%] object-cover object-center rounded absolute animate-wiggle"
            alt="hero"
            src={BoyHead}
          />
          <img
            class="w-[5%] top-[-18%] left-[29.2%] object-cover object-center rounded absolute animate-wiggle"
            alt="hero"
            src={Cup}
          />
          <img
            class="w-[2%] top-[8%] left-[39%] object-cover object-center rounded absolute animate-wiggle"
            alt="hero"
            src={Medal}
          />

          <img
            class="w-[68%] pt-[5%] object-cover z-10 object-center rounded relative animate__animated animate__zoomIn"
            alt="hero"
            src={GamificationSh}
          />

          <img
            class="w-[63%] -scale-y-100 opacity-5 object-cover object-center rounded absolute top-[75%]"
            alt="hero"
            src={Gamification}
          />

          <img
            class="w-[10%] top-[-35%] right-[35%] object-cover object-center rounded absolute animate-fly"
            alt="hero"
            src={SuperGirl}
          />

          <img
            class="w-[8%] top-[10%] right-[13%] object-cover object-center rounded absolute"
            alt="hero"
            src={KidDegree}
          />

          <img
            class="w-[2%] top-[25%] right-[12%] object-cover object-center rounded absolute animate-wiggle"
            alt="hero"
            src={KidDegreeHand}
          />
          <img
            class="w-[9%] top-[100%] right-[22.5%] object-cover object-center rounded absolute animate-rocket"
            alt="hero"
            src={Kidsskipping1}
          />
          <img
            class="w-[9%] top-[100%] right-[32.5%] object-cover object-center rounded absolute animate-reverserocket"
            alt="hero"
            src={Kidsskipping2}
          />
          <img
            class="w-[18%] top-[130%] left-[17.5%] object-cover object-center rounded absolute"
            alt="hero"
            src={KidReadingBookBody}
          />
          <img
            class="w-[8%] top-[115%] left-[22%] object-cover object-center rounded absolute animate-wiggle"
            alt="hero"
            src={KidReadingBookHead}
          />
        </div>
      </section>
    </Layout>
  )
}
